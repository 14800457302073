<template>
  <div class="bar container mb-8 mt-11 lg:mb-20">
    <icons-cross class="-mb-2 w-full lg:-mb-5" />
    <hr class="w-full border-t-[3px] border-black">
    <icons-heart class="w-full" />
    <hr class="w-full border-t-[3px] border-black">
  </div>
</template>

<style scoped>
.bar {
  @apply grid items-center gap-3;

  grid-template-columns: 16px 60% 16px auto;

  @media screen(lg) {
    grid-template-columns: 38px auto 38px auto 38px;
  }
}
</style>
